import { LogLevel } from 'tradingmate-modules/src/debug/Logger'
import { IConfig } from 'tradingmate-modules/src/environment'
import HttpProviderType from 'tradingmate-modules/src/services/httpclient/HttpProviderType'

export default {
  Http: {
    Provider: HttpProviderType.Fetch
  },
  API: {
    // Development
    // Base: 'https://localhost:44300'

    // Unknown
    // Base: 'https://localhost:50471'

    // Staging
    // Base: 'https://api.staging.tradingmate.com.au'

    // Production
    Base: 'https://api.tradingmate.com.au'
  },
  WebsiteUrls: {
    // Development
    // Public: 'http://localhost:8080',
    // Console: 'http://localhost:8081',
    // Cdn: 'http://cdn.dev.tradingmate.com.au'

    // Staging
    // Public: 'https://www.staging.tradingmate.com.au',
    // Console: 'https://my.staging.tradingmate.com.au',
    // Cdn: 'https://cdn.tradingmate.com.au'

    // Production
    // Public: 'https://www.tradingmate.com.au',
    // Console: 'https://my.tradingmate.com.au'
    // Cdn: 'https://cdn.tradingmate.com.au'

    Public: 'https://www.prod.tradingmate.com.au',
    Console: 'https://my.tradingmate.com.au',
    Cdn: 'https://cdn.tradingmate.com.au'
  },
  Logging: {
    LogLevel: LogLevel.Debug
  },
  OAuth: {
    // dev
    // ConsumerId: '4772b34e-3f03-4cbe-a63d-29fe3f7c00df'

    // live
    ConsumerId: 'b0e49bae-87d1-41d8-ad73-81ff626accbc'
  }
} as IConfig
